import axios from "axios";
import React, { useState, useEffect } from "react";

const Subscriber = () => {
  const [name, setName] = useState(0);
  const [email, setEmail] = useState(0);
  const [state, setState] = useState({
    orders: [],
  });
  const token = JSON.parse(localStorage.getItem("admin")).token;

  useEffect(() => {
    axios
      .get(`/api/admin/getSubscribers`, {
        headers: {
          "Authorization": "Bearer "+token
        }
      })
      .then((data) => {
        const orders = data.data.orders;
        setState({ orders });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const addSubscriber = async () => {
    if (!name || !email) {
      alert("Please enter the credentials");
      return;
    }
    await axios("/api/admin/subscribe/add", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+token
      },
      data: {
        name,
        email,
      },
    })
      .then((res) => {
        alert(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className="container"
      style={{ position: "relative", marginTop: "5em" }}
    >
      <h2 style={{ textAlign: "center" }}>Newsletter Subscribers</h2>
      <div className="row">
        <div className="col-8" style={{ margin: "1em auto" }}>
          <h2 style={{ textAlign: "center", marginBottom: "1em" }}>
            Add Subscriber
          </h2>
          <div className="input-group mb-3" style={{ width: "90%" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              aria-label="Name"
              aria-describedby="basic-addon1"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="input-group mb-3" style={{ width: "90%" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              aria-label="Email"
              aria-describedby="basic-addon1"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <button className="btn btn-primary" onClick={() => addSubscriber()}>
            Add Subscriber
          </button>
        </div>
      </div>
      <table style={{ margin: "0 auto", width: "80%" }}>
        <thead>
          <tr>
            <th className="bg-dark" style={{ color: "white" }}>
              Name
            </th>
            <th className="bg-dark" style={{ color: "white" }}>
              Email
            </th>
          </tr>
        </thead>
        <tbody>
          {state.orders.map((order) => (
            <tr key={order.email}>
              <td>{order.name}</td>
              <td>{order.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3 style={{ width: "100%", textAlign: "center", marginTop: "1em" }}>
        End of Subscribers
      </h3>
    </div>
  );
};

export default Subscriber;
