import React from "react";

//bootstrap imports
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const Header = () => {
  const history = useHistory();
  const signOut = () => {
    localStorage.removeItem("user");
    history.push("/login");
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/">PosheAdmin</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="Products" id="basic-nav-dropdown">
              <NavDropdown.Item href="/products">All products</NavDropdown.Item>
              <NavDropdown.Item href="/add-product">
                New product
              </NavDropdown.Item>
              <NavDropdown.Item href="/product-categories">
                Categories
              </NavDropdown.Item>
              <NavDropdown.Item href="/product-attributes">
                Attributes
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/orders">Orders</Nav.Link>
            <Nav.Link href="/coupons">Coupons</Nav.Link>
            <Nav.Link href="/customers">Customers</Nav.Link>
            <Nav.Link href="/subscribers">Subscribers</Nav.Link>
            <Nav.Link href="/wishlist">Wishlist</Nav.Link>
            <Nav.Link href="/events">Events</Nav.Link>
            {/* <Nav.Link href="/blogs">Blogs</Nav.Link> */}
            <Nav.Link href="/slider">Slider</Nav.Link>
            <Nav.Link href="/scheduler">Scheduler</Nav.Link>
            <Nav.Link href="/email-sms">Email</Nav.Link>
            <NavDropdown title="Admin" id="basic-nav-dropdown">
              <NavDropdown.Item href="/admin">Admin panel</NavDropdown.Item>
              <NavDropdown.Item href="/add-product">Settings</NavDropdown.Item>
              <NavDropdown.Item
                style={{ backgroundColor: "#af2d2d", color: "white" }}
                href="#"
                onClick={() => signOut()}
              >
                Sign out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
