import React from "react";
import { Redirect } from "react-router-dom";
import Header from "./Header";

class AuthRoute extends React.Component {
  render() {
    const Component = this.props.component;
    const isAuth = () => {
      const user = JSON.parse(localStorage.getItem("admin"));
      if (user !== null && user.token !== null) {
        return true;
      } else {
        return false;
      }
    };
    return isAuth() ? (
      <>
        <Header />
        <Component />
      </>
    ) : (
      <Redirect to={{ pathname: "/login" }} />
    );
  }
}

export default AuthRoute;
