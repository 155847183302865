import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const Attributes = () => {
  const [name, setName] = useState("");
  const [terms, setTerms] = useState("");
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    axios("api/attributes/show", {
      method: "get",
    })
      .then(async (res) => {
        //console.log(res.data.data);
        await setAttributes(res.data.data);
        /* res.data.data.forEach(async (element) => {
          const mds = attributes.concat(element);
          await console.log(mds);
          await setAttributes(mds);
          await console.log(attributes);
        }); */
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    //console.log(attributes);
  }, [attributes]);

  const deleteAttribute = async (attribute) => {
    const token = JSON.parse(localStorage.getItem("admin")).token;
    axios("api/attributes/delete", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+token
      },
      data: {
        attribute: attribute,
      },
    })
      .then((res) => {
        alert(res.data.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitForm = () => {
    const token = JSON.parse(localStorage.getItem("admin")).token;
    axios("api/attribute", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+token
      },
      data: {
        name: name,
        terms: terms,
      },
    })
      .then((res) => {
        alert(res.data.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="container">
      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Attribute name</Form.Label>
          <Form.Control
            type="text"
            placeholder="name"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Terms</Form.Label>
          <Form.Control
            type="text"
            placeholder="ex: term1,term2,term3,..."
            onChange={(e) => {
              setTerms(e.target.value);
            }}
          />
        </Form.Group>
        <Button
          variant="primary"
          onClick={() => {
            submitForm();
          }}
        >
          Submit
        </Button>
      </Form>
      <h2 className="mt-4">Attributes</h2>
      <Table className="mt-4" hover>
        <thead>
          <tr>
            <th>Attribute Name</th>
            <th>Terms</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {attributes.map((attr, i) => (
            <tr key={i}>
              <td>{attr.attribute_name}</td>
              <td>{attr.attribute_value}</td>
              <td style={{ textAlign: "center" }}>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  as={Button}
                  onClick={() => {
                    deleteAttribute(attr.attribute_name);
                  }}
                  icon={faTrash}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Attributes;
