import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Col,
  Container,
  Dropdown,
  FormControl,
  InputGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../styles/AllProducts.scss";

const AllProducts = () => {
  const user = JSON.parse(localStorage.getItem("admin"));
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState("0");
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const history = useHistory();
  const [state, setState] = useState({});
  const [search, setSearch] = useState("");

  const getCount = (c) => {
    axios("/api/products/count/" + c, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }).then((res) => {
      setState({
        all: res.data.data,
      });
      setPages(Math.floor(res.data.data / 20) + 1);
    });
  };

  useEffect(() => {
    getCount("0");
  }, []);
  useEffect(() => {}, [state]);

  useEffect(() => {}, [categoryList]);
  useEffect(() => {}, [products]);

  useEffect(() => {
    const mds = async () => {
      try {
        const res = await axios("/api/categories/show", {
          method: "get",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        await setCategoryList(res.data.data);
        //await console.log(categoryList);
        //await console.log(category); //, categoryList[0]);
      } catch (error) {
        console.log(error);
      }
    };
    mds();
  }, []);

  useEffect(() => {
    setPageNumber(1);
    axios(`/api/products/category/${category}/${pageNumber}/20`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }).then((res) => {
      refreshCheckbox();
      setProducts(res.data);
      document.querySelector("#spinner").style.display = "none";
    });
    if (category === "All") {
      getCount("0");
    } else {
      getCount(category);
    }
    console.log(products);
  }, [category]);

  useEffect(() => {
    axios(`/api/products/category/${category}/${pageNumber}/20`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }).then((res) => {
      refreshCheckbox();
      setProducts(res.data);
    });
  }, [pageNumber]);

  const editProduct = (id) => {
    history.push("/edit-product/" + id);
  };

  const deleteProduct = async (id) => {
    if (window.confirm("Do you want to delete this product?")) {
      await axios("/api/products/delete", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: {
          id: id,
        },
      })
        .then((res) => {
          alert("Product deleted");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addToCategory = async (id, cat) => {
    await axios("/api/products/update", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      data: {
        id: id,
        mode: "append",
        key: "category",
        value: cat,
      },
    })
      .then((res) => {
        alert("Added to " + cat + " products");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeFromCategory = async (id, cat) => {
    await axios("/api/products/update", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      data: {
        id: id,
        mode: "remove",
        key: "category",
        value: cat,
      },
    })
      .then((res) => {
        alert("Removed from " + cat + " products");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchIt = () => {
    axios(`/api/product/sku/search`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      method: "post",
      data: {
        sku: search,
      },
    })
      .then((res) => {
        setPageNumber(1);
        setPages(1);
        setProducts(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const bulkAction = async (action) => {
    const checkbox = document.getElementsByName("indCheck");
    const selected = [];
    for (let i = 0; i < checkbox.length; i++) {
      const element = checkbox[i];
      if (element.checked) {
        selected.push(element.value);
      }
    }
    if (action === "delete") {
      const check = window.confirm(
        "Do you want to delete the selected products?"
      );
      if (check) {
        await axios("/api/products/deleteBulk", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          data: {
            ids: selected,
          },
        })
          .then((res) => {
            alert("Products deleted successfully");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const checkAll = () => {
    const checkbox = document.querySelector("#checkAll");
    if (checkbox.checked) {
      const children = document.getElementsByName("indCheck");
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        child.checked = true;
      }
    } else {
      const children = document.getElementsByName("indCheck");
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        child.checked = false;
      }
    }
  };

  const getDate = (ft) => {
    const d = new Date(ft);
    const date =
      d.getFullYear() +
      "/" +
      d.getMonth() +
      "/" +
      d.getDate() +
      " - " +
      d.getHours() +
      ":" +
      d.getMinutes() +
      ":" +
      d.getSeconds();
    return date;
    //return `${d.getDate()} ${d.getHours()} ${d.getMinutes()}`;
  };

  const refreshCheckbox = () => {
    const cbox = document.getElementsByTagName("input");
    for (let i = 0; i < cbox.length; i++) {
      const cb = cbox[i];
      cb.checked = false;
    }
  };

  return (
    <div className="container">
      <div className="heading mt-4 mb-4">
        <h3>Product inventory</h3>
      </div>
      <Container className="filters mt-2 mb-2" as={Row}>
        <Dropdown as={Col} sm={{ span: 2, offset: 4 }}>
          <Dropdown.Toggle variant="dark" id="dropdown-basic">
            Category
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setCategory(0);
              }}
            >
              All
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setCategory("FEATURED");
              }}
            >
              FEATURED PRODUCTS
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setCategory("NEW");
              }}
            >
              NEW PRODUCTS
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setCategory("SPECIAL");
              }}
            >
              SPECIAL PRODUCTS
            </Dropdown.Item>
            {categoryList &&
              categoryList.map((cat, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={() => {
                    setCategory(cat);
                  }}
                >
                  {cat}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown as={Col} sm={{ span: 2 }}>
          <Dropdown.Toggle variant="dark" id="dropdown-basic">
            Bulk action
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                bulkAction("delete");
              }}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <InputGroup as={Col} sm={{ span: 2 }}>
          <FormControl
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            value={search}
            placeholder="SKU"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </InputGroup>
        <Button
          as={Col}
          sm={{ span: 2 }}
          variant="dark"
          onClick={() => {
            searchIt();
          }}
        >
          Search
        </Button>
      </Container>
      <div className="productsList">
        <Table hover>
          <thead>
            <tr>
              <th>
                <InputGroup.Checkbox
                  type="checkbox"
                  id="checkAll"
                  onChange={() => {
                    checkAll();
                  }}
                />
              </th>
              <th>Image</th>
              <th>Name</th>
              <th>SKU</th>
              <th>Stock Status</th>
              <th>Category</th>
              <th>Priority</th>
              <th>Last modification</th>
              <th colSpan="1">Featured</th>
              <th colSpan="1">New</th>
              <th colSpan="1">Special</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody style={{ width: "100% !important", overflowX: "scroll" }}>
            {products && products?.map((prod, i) => (
              <tr key={i}>
                <td>
                  <InputGroup.Checkbox
                    type="checkbox"
                    name="indCheck"
                    value={prod._id}
                  />
                </td>
                <td>
                  <img
                    style={{ width: "2em", height: "2.5em" }}
                    src={
                      !prod.image
                        ? `/api/image/${prod.images[0]}`
                        : `/api/image/${prod.image}`
                    }
                    alt="product"
                  />
                </td>
                <td>{prod.name}</td>
                <td>{prod.sku}</td>
                <td>{prod.stockStatus}</td>
                <td>{prod.category}</td>
                <td>{prod.priority}</td>
                <td>{getDate(prod.createdOn)}</td>
                <td>
                  {/^.*?featured.*?$/i.test(prod?.category) ? (
                    <InputGroup.Checkbox
                      checked
                      type="checkbox"
                      name="featured"
                      onChange={() => {
                        removeFromCategory(prod._id, "FEATURED");
                      }}
                    />
                  ) : (
                    <InputGroup.Checkbox
                      type="checkbox"
                      name="featured"
                      onChange={() => {
                        addToCategory(prod._id, "FEATURED");
                      }}
                    />
                  )}
                </td>
                <td>
                  {/^.*?new.*?$/i.test(prod?.category) ? (
                    <InputGroup.Checkbox
                      checked
                      type="checkbox"
                      name="new"
                      onChange={() => {
                        removeFromCategory(prod._id, "NEW");
                      }}
                    />
                  ) : (
                    <InputGroup.Checkbox
                      type="checkbox"
                      name="new"
                      onChange={() => {
                        addToCategory(prod._id, "NEW");
                      }}
                    />
                  )}
                </td>
                <td>
                  {/^.*?special.*?$/i.test(prod?.category) ? (
                    <InputGroup.Checkbox
                      checked
                      type="checkbox"
                      name="special"
                      onChange={() => {
                        removeFromCategory(prod._id, "SPECIAL");
                      }}
                    />
                  ) : (
                    <InputGroup.Checkbox
                      type="checkbox"
                      name="special"
                      onChange={() => {
                        addToCategory(prod._id, "SPECIAL");
                      }}
                    />
                  )}
                </td>
                <td>
                  <FontAwesomeIcon
                    as={Button}
                    style={{
                      margin: "auto 0.5em",
                      fontSize: "1em",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      editProduct(prod._id);
                    }}
                    icon={faEdit}
                  />

                  <FontAwesomeIcon
                    as={Button}
                    style={{
                      margin: "auto 0.5em",
                      fontSize: "1em",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      deleteProduct(prod._id);
                    }}
                    icon={faTrash}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div
          id="spinner"
          style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0.8,
            background: "white",
          }}
        >
          <Spinner
            animation="border"
            className="mx-auto my-auto"
            style={{
              width: "5em",
              height: "5em",
              position: "relative",
            }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pages}
          pageRangeDisplayed={pages}
          onPageChange={(e) => {
            setPageNumber(e.selected + 1);
            window.scrollTo(0, 0);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default AllProducts;
