import React, { useEffect, useState, createRef } from "react";
import { Card, Col, Container, Dropdown, Row, Table } from "react-bootstrap";
import axios from "axios";
import NumberFormat from "react-number-format";
import { Button } from "react-bootstrap";
const moment = require("moment");
const fs = require('fs');

const OrderChild = (props) => {
  const [orderDetails, setOrderDetails] = useState({});
  const [items, setItems] = useState([]);
  const getOrderData = () => {
    const token = JSON.parse(localStorage.getItem("admin")).token;
    axios("/api/order/single/" + props.mds, {headers:{"Authorization": "Bearer "+token}})
      .then(async (res) => {
        await setOrderDetails(res.data[0]);
        await setItems(JSON.parse(res.data[0]?.items));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrderData();
  }, [props.mds]);

  const changeStatus = async (status) => {
    let data = {};
    const token = JSON.parse(localStorage.getItem("admin")).token;
    await axios("/api/order/status", {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+token
      },
      data: {
        orderId: orderDetails.orderId,
        status: status,
      },
    })
      .then((res) => {
        data = { ...data, orderId: orderDetails.orderId, message: status };
        if (status !== "Processing") {
          alert(res.data);
        } else {
          const trackingLink = prompt("Enter the tracking ID");
          if (trackingLink === null || trackingLink === "") {
            alert("Please enter the tracking ID and try again!");
            return;
          } else {
            data = { ...data, trackingLink };
          }
        }
        axios("/api/sms/order", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer "+token
          },
          data: data,
        }).then((res1) => {
          alert(`${status} ${res1.data}`);
          getOrderData();
        });
      })
      .catch((err) => {
        console.log(err);
      });

    if (status === "Delivered") {
      await axios
        .post("/api/admin/add/wallet", {
          orderId: props.mds,
        }, {
          headers: {
            "Authorization": "Bearer "+token
          }
        })
        .then((res) => {
          alert(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const generateInvoice = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("admin")).token;
      const res = await axios.post("/api/admin/generateInvoice", {
        orderId: orderDetails.orderId,
      },{headers:{"Authorization": "Bearer "+token}});
      await alert(res.data);
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  const checkInvoice = async () => {
    /*window.open(
      "https://poshemode.com/api/admin/downloadInvoice/" + orderDetails.orderId,
      "_blank"
    );*/
    try{
      const token = JSON.parse(localStorage.getItem("admin")).token;
      const result = await axios({url:"/api/admin/downloadInvoice/"+orderDetails.orderId, method: 'get', responseType: 'blob', headers: {"Authorization": "Bearer "+token}});
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', orderDetails.orderId+".pdf");
      document.body.appendChild(link);
      link.click();
    }catch (err) {
      console.log(err);
      alert(err);
    }
  };

  const approveOrder = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("admin")).token;
      const res = await axios.post("/api/admin/sendInvoice", {
        orderId: orderDetails.orderId,
      }, {
        headers: {"Authorization": "Bearer "+token}
      });
      await alert(res.data);
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  const orderStatus = [
    "Payment pending",
    "Processing",
    "Delivered",
    "Canceled",
  ];
  if (props.mds === 0) {
    return <Container>Please select the order</Container>;
  }
  return (
    <Container>
      <h3>
        Order ID <b>#{orderDetails?.orderId}</b>
      </h3>
      <p>
        <b>Payment method:</b> {orderDetails?.paymentMethod} |{" "}
        <span style={{ fontSize: "0.8em" }}>
          Created at{" "}
          {moment(orderDetails?.createdOn).format("DD/MM/YYYY, hh:mm a")}
        </span>
      </p>
      <hr />
      <Row>
        <Col sm={{ span: 6 }}>
          <h4>
            <b>Shipping details</b>
          </h4>
          <p>
            {orderDetails?.shippingName}
            <br />
            {orderDetails?.shippingAddress1}
            <br />
            {orderDetails?.shippingAddress2}
            {orderDetails?.shippingCity}
            <br />
            {orderDetails?.shippingState}
            <br />
            {orderDetails?.shippingCountry}
            {orderDetails?.shippingPincode}
          </p>
        </Col>
        <Col sm={{ span: 6 }}>
          <h4>
            <b>Billing details</b>
          </h4>
          <p>
            <a href={"mailto:" + orderDetails?.billingEmail}>
              {orderDetails?.billingEmail}
            </a>
            <br />
            <a href={"tel:" + orderDetails?.billingPhone}>
              {orderDetails?.billingPhone}
            </a>
            <br />
            {orderDetails?.billingName}
            <br />
            {orderDetails?.billingAddress1}
            <br />
            {orderDetails?.billingAddress2}
            {orderDetails?.billingCity}
            <br />
            {orderDetails?.billingState}
            <br />
            {orderDetails?.billingCountry}
            {orderDetails?.billingPincode}
          </p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h4>
            <b>Order Status</b>
          </h4>
          <Dropdown>
            <Dropdown.Toggle>{orderDetails?.status}</Dropdown.Toggle>
            <Dropdown.Menu>
              {orderStatus?.map((status, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={() => {
                    changeStatus(status);
                  }}
                >
                  {status}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col>
          <Button onClick={() => generateInvoice()}>Generate</Button>
        </Col>
        <Col>
          <Button onClick={() => checkInvoice()}>Check</Button>
        </Col>
        <Col>
          <Button onClick={() => approveOrder()}>Approve</Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h4>
                <b>Items</b>
              </h4>
            </Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Product</th>
                    <th>Variant</th>
                    <th>SKU</th>
                    <th>Cost</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {items?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <img
                          style={{ width: "2em", height: "2.5em" }}
                          src={"/api/image/" + item?.image}
                          alt="product pic"
                        />
                      </td>
                      <td>{item?.name}</td>
                      <td>{item?.variation}</td>
                      <td>{item?.sku}</td>
                      <td>
                        <NumberFormat
                          value={item?.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₹"}
                        />
                      </td>
                      <td>{item?.quantity}</td>
                      <td>
                        <NumberFormat
                          value={item?.price * item?.quantity}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₹"}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>Total</b>
                    </td>
                    <td>
                      <NumberFormat
                        value={orderDetails?.total}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₹"}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <p>
                Coupon: <b>{orderDetails?.couponApplied}</b>
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [orderId, setOrderId] = useState(0);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("admin")).token;
    axios("/api/order", {headers:{"Authorization": "Bearer "+token}})
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Container className="mt-3 mb-3">
      <h1>Orders</h1>
      <Row>
        <Col
          sm={5}
          style={{
            borderRight: "1px solid gray",
            maxHeight: "100vh",
            overflowY: "scroll",
          }}
        >
          <Table hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Status</th>
                <th>Date</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orders.length !== 0 ? (
                orders.map((order, i) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={i}
                    onClick={() => {
                      setOrderId(order.orderId);
                    }}
                  >
                    <td>{order.orderId}</td>
                    <td>{order.status}</td>
                    <td>
                      {moment(order.createdOn).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                    <td>
                      <NumberFormat
                        value={order.total}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₹"}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No orders found!</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col sm={7}>
          <OrderChild mds={orderId} />
        </Col>
      </Row>
    </Container>
  );
};

export default Orders;
