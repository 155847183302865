import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";

const Slider = () => {
  const uploadSlider = async (filename, file) => {
    const formData = new FormData();
    formData.append("mds", file);
    axios
      .post(`/api/stats/static/${filename}`, formData)
      .then((res) => {
        console.log(res);
        alert("uploaded!");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadSliderSchedule = async (filename, file) => {
    const formData = new FormData();
    formData.append("mds", file);
    axios
      .post(`/api/stats/static/fest/${filename}`, formData)
      .then((res) => {
        console.log(res);
        alert("uploaded!");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container className="mt-2 mb-2">
      <Row>
        <Col>
          <h3>Slider 1</h3>
          <div
            style={{
              width: "20em",
              height: "16em",
              position: "relative",
            }}
          >
            <input
              type="file"
              style={{
                position: "absolute",
                zIndex: 2,
                opacity: 0,
                height: "100%",
                width: "100%",
              }}
              onChange={(e) => {
                uploadSlider("slider1", e.target.files[0]);
              }}
            />
            <img
              src="/api/stats/static/slider1.jpg"
              alt="mds"
              style={{
                zIndex: 1,
                position: "absolute",
                width: "100%",
              }}
            />
          </div>
        </Col>
        <Col>
          <h3>Slider 2</h3>
          <div
            style={{
              width: "20em",
              height: "16em",
              position: "relative",
            }}
          >
            <input
              type="file"
              style={{
                position: "absolute",
                zIndex: 2,
                opacity: 0,
                height: "100%",
                width: "100%",
              }}
              onChange={(e) => {
                uploadSlider("slider2", e.target.files[0]);
              }}
            />
            <img
              src="/api/stats/static/slider2.jpg"
              alt="mds"
              style={{
                zIndex: 1,
                position: "absolute",
                width: "100%",
              }}
            />
          </div>
        </Col>
        <Col>
          <h3>Slider 3</h3>
          <div
            style={{
              width: "20em",
              height: "16em",
              position: "relative",
            }}
          >
            <input
              type="file"
              style={{
                position: "absolute",
                zIndex: 2,
                opacity: 0,
                height: "100%",
                width: "100%",
              }}
              onChange={(e) => {
                uploadSlider("slider3", e.target.files[0]);
              }}
            />
            <img
              src="/api/stats/static/slider3.jpg"
              alt="mds"
              style={{
                zIndex: 1,
                position: "absolute",
                width: "100%",
              }}
            />
          </div>
        </Col>
        <Col className="border">
          <h3>Infographic</h3>
          <div
            style={{
              width: "20em",
              height: "16em",
              position: "relative",
            }}
          >
            <input
              type="file"
              style={{
                position: "absolute",
                zIndex: 2,
                opacity: 0,
                height: "100%",
                width: "100%",
              }}
              onChange={(e) => {
                uploadSlider("infographic", e.target.files[0]);
              }}
            />
            <img
              src="/api/stats/static/infographic.jpg"
              alt="mds"
              style={{
                zIndex: 1,
                position: "absolute",
                width: "100%",
              }}
            />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "10em" }}>
        <Col>
          <h1>Scheduled assets</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Slider 1</h3>
          <div
            style={{
              width: "20em",
              height: "16em",
              position: "relative",
            }}
          >
            <input
              type="file"
              style={{
                position: "absolute",
                zIndex: 2,
                opacity: 0,
                height: "100%",
                width: "100%",
              }}
              onChange={(e) => {
                uploadSliderSchedule("slider1", e.target.files[0]);
              }}
            />
            <img
              src="/api/stats/static/fest/slider1.jpg"
              alt="mds"
              style={{
                zIndex: 1,
                position: "absolute",
                width: "100%",
              }}
            />
          </div>
        </Col>
        <Col>
          <h3>Slider 2</h3>
          <div
            style={{
              width: "20em",
              height: "16em",
              position: "relative",
            }}
          >
            <input
              type="file"
              style={{
                position: "absolute",
                zIndex: 2,
                opacity: 0,
                height: "100%",
                width: "100%",
              }}
              onChange={(e) => {
                uploadSliderSchedule("slider2", e.target.files[0]);
              }}
            />
            <img
              src="/api/stats/static/fest/slider2.jpg"
              alt="mds"
              style={{
                zIndex: 1,
                position: "absolute",
                width: "100%",
              }}
            />
          </div>
        </Col>
        <Col>
          <h3>Slider 3</h3>
          <div
            style={{
              width: "20em",
              height: "16em",
              position: "relative",
            }}
          >
            <input
              type="file"
              style={{
                position: "absolute",
                zIndex: 2,
                opacity: 0,
                height: "100%",
                width: "100%",
              }}
              onChange={(e) => {
                uploadSliderSchedule("slider3", e.target.files[0]);
              }}
            />
            <img
              src="/api/stats/static/fest/slider3.jpg"
              alt="mds"
              style={{
                zIndex: 1,
                position: "absolute",
                width: "100%",
              }}
            />
          </div>
        </Col>
        <Col className="border" style={{ marginTop: "5em" }}>
          <h3>Infographic</h3>
          <div
            style={{
              width: "20em",
              height: "16em",
              position: "relative",
            }}
          >
            <input
              type="file"
              style={{
                position: "absolute",
                zIndex: 2,
                opacity: 0,
                height: "100%",
                width: "100%",
              }}
              onChange={(e) => {
                uploadSliderSchedule("infographic", e.target.files[0]);
              }}
            />
            <img
              src="/api/stats/static/fest/infographic.jpg"
              alt="mds"
              style={{
                zIndex: 1,
                position: "absolute",
                width: "100%",
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Slider;
