import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";

const Category = () => {
  const [attributes, setAttributes] = useState([]);
  const token = JSON.parse(localStorage.getItem("admin")).token;

  useEffect(() => {
    axios("/api/events", {
      method: "get",
      headers: {
        "Authorization": "Bearer "+token
      }
    })
      .then(async (res) => {
        console.log(res.data);
        await setAttributes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(attributes);
  }, [attributes]);

  return (
    <div className="container">
      <h2 className="mt-4">Event Orders</h2>
      <Table className="mt-4" striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Message</th>
            <th>Booking time</th>
          </tr>
        </thead>
        <tbody>
          {attributes.map((attr, i) => (
            <tr key={i}>
              <td>{attr.name}</td>
              <td>{attr.phone}</td>
              <td>{attr.email}</td>
              <td>{attr.message}</td>
              <td>{new Date(attr.booked_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Category;
