import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import axios from "axios";

const Home = () => {
  let username = "user";

  const [all, setAll] = useState(0);
  const [blogs, setBlogs] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [ordersPending, setOrdersPending] = useState(0);
  const [ordersCompleted, setOrdersCompleted] = useState(0);

  useEffect(() => {
    axios("api/products/count/0").then((res) => {
      setAll(res.data.data);
    });
    axios("api/stats/count/blogs").then((res) => {
      setBlogs(res.data);
    });
    axios("api/stats/count/customers").then((res) => {
      setCustomers(res.data);
    });
    axios("api/stats/count/ordersPending").then((res) => {
      setOrdersPending(res.data);
    });
    axios("api/stats/count/ordersCompleted").then((res) => {
      setOrdersCompleted(res.data);
    });
  }, []);

  return (
    <Container>
      <h1 style={{ margin: "1em 0" }}>Welcome {username},</h1>
      <div
        className="d-flex flex-wrap"
        style={{ justifyContent: "space-evenly" }}
      >
        <Card
          style={{ width: "18rem", margin: "1em", backgroundColor: "#f4f4f4" }}
        >
          <Card.Body>
            <Card.Title>Products</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Total: {all}
            </Card.Subtitle>
            <Card.Link href="/products">View products</Card.Link>
          </Card.Body>
        </Card>

        <Card
          style={{ width: "18rem", margin: "1em", backgroundColor: "#f4f4f4" }}
        >
          <Card.Body>
            <Card.Title>Orders</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Orders pending: {ordersPending}
              <br />
              Orders Completed: {ordersCompleted}
            </Card.Subtitle>
            <Card.Link href="/orders">View orders</Card.Link>
          </Card.Body>
        </Card>

        <Card
          style={{ width: "18rem", margin: "1em", backgroundColor: "#f4f4f4" }}
        >
          <Card.Body>
            <Card.Title>Customers</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Total: {customers}
            </Card.Subtitle>
            <Card.Link href="/customers">View customers</Card.Link>
          </Card.Body>
        </Card>

        <Card
          style={{ width: "18rem", margin: "1em", backgroundColor: "#f4f4f4" }}
        >
          <Card.Body>
            <Card.Title>Blogs</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Total: {blogs}
            </Card.Subtitle>
            <Card.Link href="/blogs">View blogs</Card.Link>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default Home;
