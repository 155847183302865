import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const Attributes = () => {
  const [state, setState] = useState({});

  useEffect(() => {
    axios("api/stats/showSchedules", {
      method: "get",
    })
      .then(async (res) => {
        await setState({ events: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const submitForm = async () => {
    axios("api/stats/addSchedule", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ename: await state.newName,
        stime: await state.newStime,
        etime: await state.newEtime,
      },
    })
      .then((res) => {
        alert(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const update = async () => {
    axios("api/stats/updateEvent", {
      method: "get",
    })
      .then((res) => {
        alert(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      });
  };
  return (
    <div className="container">
      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Event name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Event name"
            onChange={(e) => {
              setState({ ...state, newName: e.target.value });
            }}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Start date</Form.Label>
          <Form.Control
            type="date"
            onChange={(e) => {
              setState({ ...state, newStime: e.target.value });
            }}
          />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>End date</Form.Label>
          <Form.Control
            type="date"
            onChange={(e) => {
              setState({ ...state, newEtime: e.target.value });
            }}
          />
        </Form.Group>
        <Button
          variant="primary"
          onClick={() => {
            submitForm();
          }}
        >
          Submit
        </Button>
      </Form>
      <Button
        style={{ marginTop: "2em" }}
        variant="primary"
        onClick={() => {
          update();
        }}
      >
        Refresh
      </Button>
      <h2 className="mt-4">Events</h2>
      <Table className="mt-4" hover>
        <thead>
          <tr>
            <th>Event Name</th>
            <th>Start</th>
            <th>End</th>
          </tr>
        </thead>
        <tbody>
          {state.events &&
            state.events.map((attr, i) => (
              <tr key={i}>
                <td>{attr.ename}</td>
                <td>{new Date(attr.stime).toString()}</td>
                <td>{new Date(attr.etime).toString()}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Attributes;
