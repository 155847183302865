import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";

const Category = () => {
  const [name, setName] = useState("");
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    axios("api/categories/show", {
      method: "get",
    })
      .then(async (res) => {
        console.log(res.data.data);
        await setAttributes(res.data.data);
        /* res.data.data.forEach(async (element) => {
          const mds = attributes.concat(element);
          await console.log(mds);
          await setAttributes(mds);
          await console.log(attributes);
        }); */
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(attributes);
  }, [attributes]);

  const submitForm = () => {
    const token = JSON.parse(localStorage.getItem("admin")).token;
    axios("api/category", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+token
      },
      data: {
        name: name,
      },
    })
      .then((res) => {
        alert(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="container">
      <Form className="mt-4">
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Category name</Form.Label>
          <Form.Control
            type="text"
            placeholder="name"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Form.Group>
        <Button
          variant="primary"
          onClick={() => {
            submitForm();
          }}
        >
          Submit
        </Button>
      </Form>
      <h2 className="mt-4">Categories</h2>
      <Table className="mt-4" striped bordered hover>
        <thead>
          <tr>
            <th>Category Name</th>
          </tr>
        </thead>
        <tbody>
          {attributes.map((attr, i) => (
            <tr key={i}>
              <td>{attr}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Category;
