import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import axios from "axios";
const moment = require("moment");

const CustomerChild = (props) => {
  const [customerDetails, setCustomerDetails] = useState({});
  const token = JSON.parse(localStorage.getItem("admin")).token;
  const getCustomerData = () => {
    axios("/api/customer/data/" + props.mds, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(async (res) => {
        await setCustomerDetails(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCustomerData();
  }, [props.mds]);

  if (props.mds === 0) {
    return <Container>Please select the customer to view details</Container>;
  }
  return (
    <Container>
      <h3>
        Customer ID <b>{customerDetails?.id}</b>
      </h3>
      <p>
        Name: <b>{customerDetails?.name}</b>
        <br />
        Username: <b>{customerDetails?.username}</b>
        <br />
        Email: <b>{customerDetails?.email}</b>
        <br />
        Phone: <b>{customerDetails?.phone}</b>
        <br />
        DOB: <b>{moment(customerDetails?.dob).format("DD/MM/YYYY")}</b>
        <br />
        Wallet: <b>{customerDetails?.wallet}</b>
      </p>
      <hr />
      <Row>
        <Col sm={{ span: 6 }}>
          <h4>
            <b>Shipping details</b>
          </h4>
          <p>
            {customerDetails?.shippingName}
            <br />
            {customerDetails?.shippingAddress1}
            <br />
            {customerDetails?.shippingAddress2}
            {customerDetails?.shippingCity}
            <br />
            {customerDetails?.shippingState}
            <br />
            {customerDetails?.shippingCountry}
            {customerDetails?.shippingPincode}
          </p>
        </Col>
        <Col sm={{ span: 6 }}>
          <h4>
            <b>Billing details</b>
          </h4>
          <p>
            <a href={"mailto:" + customerDetails?.billingEmail}>
              {customerDetails?.billingEmail}
            </a>
            <br />
            <a href={"tel:" + customerDetails?.billingPhone}>
              {customerDetails?.billingPhone}
            </a>
            <br />
            {customerDetails?.billingName}
            <br />
            {customerDetails?.billingAddress1}
            <br />
            {customerDetails?.billingAddress2}
            {customerDetails?.billingCity}
            <br />
            {customerDetails?.billingState}
            <br />
            {customerDetails?.billingCountry}
            {customerDetails?.billingPincode}
          </p>
        </Col>
      </Row>
      <hr />
    </Container>
  );
};

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState(0);
  const token = JSON.parse(localStorage.getItem("admin")).token;
  useEffect(() => {
    axios("/api/customer", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Container className="mt-3 mb-3">
      <h1>Customers</h1>
      <Row>
        <Col
          sm={6}
          style={{
            borderRight: "1px solid gray",
            height: "100vh",
            overflowY: "scroll",
          }}
        >
          <Table hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Username</th>
                <th>Phone</th>
                <th>DOB</th>
                <th>Wallet</th>
              </tr>
            </thead>
            <tbody>
              {customers?.map((customer, i) => (
                <tr
                  style={{ cursor: "pointer" }}
                  key={i}
                  onClick={() => {
                    setCustomerId(customer.id);
                  }}
                >
                  <td>{customer.id}</td>
                  <td>{customer.username}</td>
                  <td>{customer.phone}</td>
                  <td>{moment(customer.dob).format("DD/MM/YYYY")}</td>
                  <td>{customer.wallet}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col sm={6}>
          <CustomerChild mds={customerId} />
        </Col>
      </Row>
    </Container>
  );
};

export default Customer;
