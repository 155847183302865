import React, { useRef } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "react-phone-number-input/style.css";
import axios from "axios";
import SunEditor, { buttonList } from "suneditor-react";

const EmailSMS = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [haveImage, setHaveImage] = useState(1);
  const [imagePosition, setImagePosition] = useState("");

  const changeBody = (content) => {
    setBody(content);
  };

  const editorRef = useRef();

  const getConfig = (ht) => {
    return {
      height: ht,
      buttonList: buttonList.complex,
    };
  };

  const bodyEditor = () => {
    return (
      <SunEditor
        ref={editorRef}
        setOptions={getConfig(500)}
        onChange={changeBody}
      />
    );
  };

  const sendEmail = async () => {
    const token = JSON.parse(localStorage.getItem("admin")).token;
    if (haveImage === "0") {
      console.log(haveImage);
      const file = document.querySelector("#blogImage").files[0];
      const formData1 = new FormData();
      formData1.append("mds", file);
      const res = await axios.post("/api/email/custom/poster", formData1, {headers: {"Authorization": "Bearer "+token}});
      if (res.data.data !== undefined) {
        alert("Image uploaded!");
      }
    }
    await axios("/api/email/custom", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+token
      },
      data: {
        subject: title,
        body,
        haveImage,
        imagePosition,
      },
    })
      .then((res1) => {
        alert(res1.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container className="mt-4 mb-4">
      <h3>Email</h3>
      <Form>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Subject
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Title"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Body
          </Form.Label>
          <Col sm={10}>{bodyEditor()}</Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Have Image
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setHaveImage(e.target.value);
              }}
            >
              <option value=""></option>
              <option value={0}>Yes</option>
              <option value={1}>No</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Image position
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setImagePosition(e.target.value);
              }}
            >
              <option value=""></option>
              <option value="top">Top</option>
              <option value="bottom">Bottom</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Image
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="file" id="blogImage" />
          </Col>
        </Form.Group>
        <Button
          sm={{ span: 2, offset: 8 }}
          variant="primary"
          onClick={() => {
            sendEmail();
          }}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default EmailSMS;
