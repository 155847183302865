import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import axios from "axios";
const moment = require("moment");

const Wishlist = () => {
  const [wishlists, setWishlists] = useState([]);
  const token = JSON.parse(localStorage.getItem("admin")).token;

  useEffect(async () => {
    try {
      const { data } = await axios("/api/wishlist/admin", {method: 'get', headers: {"Content-Type": "application/json","Authorization": "Bearer "+token}});
      await console.log(data);
      setWishlists(data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Container className="mt-3 mb-3">
      <h1>Customers</h1>
      <Row>
        <Col sm={12}>
          <Table hover>
            <thead>
              <tr>
                <th>Customer id</th>
                <th>Wishlist data</th>
              </tr>
            </thead>
            <tbody>
              {wishlists &&
                wishlists?.map((customer, i) => (
                  <tr style={{ cursor: "pointer" }} key={i}>
                    <td>{customer.customer_id}</td>
                    <td>
                      {customer.wishlist_data !== "[]"
                        ? JSON.parse(customer.wishlist_data)?.map((cd, j) => (
                            <p key={`${i}${j}`}>
                              {JSON.parse(customer.wishlist_data)?.length === ""
                                ? "true"
                                : ""}
                              <img
                                style={{ width: "3em", height: "4em" }}
                                src={
                                  "https://poshemode.com/api/image/" + cd.image
                                }
                              />
                              <br />
                              <b>Name : </b> {cd?.name} <br />
                              <b>Variant : </b>
                              {cd?.variation !== "" ? cd?.variation : "None"}
                              <br />
                              <b>Added on :</b>{" "}
                              {cd.addedOn
                                ? new Date(
                                    parseInt(cd?.addedOn)
                                  ).toLocaleString("en-US")
                                : "No date"}
                            </p>
                          ))
                        : "No items in wishlist!"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Wishlist;
