import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import axios from "axios";
import SunEditor, { buttonList } from "suneditor-react";
import { useRef } from "react";

const Blog = () => {
  const user = JSON.parse(localStorage.getItem("admin"));
  const [blogs, setBlogs] = useState([]);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    axios("/api/blog", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        setBlogs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const createBlog = async () => {
    const file = document.querySelector("#blogImage").files[0];
    const formData1 = new FormData();
    formData1.append("mds", file);
    const res = await axios.post("api/products/images", formData1);
    console.log(res);
    await axios(`/api/blogs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      data: {
        title: title,
        body: body,
        image: res.data.data,
      },
    })
      .then((res) => {
        alert(res.data.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteBlog = (id) => {
    axios("/api/blogs/delete", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      data: {
        id: id,
      },
    })
      .then((res) => {
        alert(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editorRef = useRef();

  const getConfig = (ht) => {
    return {
      height: ht,
      buttonList: buttonList.complex,
    };
  };

  const changeBody = (content) => {
    setBody(content);
  };

  const bodyEditor = () => {
    return (
      <SunEditor
        ref={editorRef}
        setOptions={getConfig(500)}
        onChange={changeBody}
      />
    );
  };

  return (
    <Container className="mt-4 mb-4">
      <h3>
        <b>Create blog</b>
      </h3>
      <Form>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Title
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Title"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Body
          </Form.Label>
          <Col sm={10}>{bodyEditor()}</Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Image
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="file" id="blogImage" />
          </Col>
        </Form.Group>
        <Button
          sm={{ span: 2, offset: 8 }}
          variant="primary"
          onClick={() => {
            createBlog();
          }}
        >
          Submit
        </Button>
      </Form>
      <h3 className="mt-4">
        <b>Blogs</b>
      </h3>
      <Table className="mt-4" hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {blogs?.map((attr, i) => (
            <tr key={i}>
              <td>{attr._id}</td>
              <td>{attr.title}</td>
              <td>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  as={Button}
                  onClick={() => {
                    deleteBlog(attr._id);
                  }}
                  icon={faTrash}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Blog;
