import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import axios from "axios";
const moment = require("moment");

const Coupon = () => {
  const [coupons, setCoupons] = useState([]);
  const [code, setCode] = useState("");
  const [usageLimit, setUsageLimit] = useState(0);
  const [dayLimit, setDayLimit] = useState("");
  const [validCategory, setValidCategory] = useState("All");
  const [validProduct, setValidProduct] = useState("All");
  const [discountType, setDiscountType] = useState("Percentage");
  const [discount, setDiscount] = useState(0);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios("/api/coupon")
      .then((res) => {
        setCoupons(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios("/api/categories/show")
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const createCoupon = () => {
    let mds;
    if (code === "") {
      mds = undefined;
    } else {
      mds = code;
    }
    axios(`/api/coupon`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        code: mds,
        usageLimit: usageLimit,
        dayLimit: dayLimit,
        validCategory: validCategory,
        validProduct,
        discountType: discountType,
        discountAmount: discount,
      },
    })
      .then((res) => {
        alert(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCoupon = (code) => {
    const token = JSON.parse(localStorage.getItem("admin")).token;
    axios("/api/coupon/", {
      method: "delete",
      headers: {
        Authorization: "Bearer " + token,
      },
      data: {
        code: code,
      },
    })
      .then((res) => {
        alert(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Container className="mt-4 mb-4">
      <h3>
        <b>Create coupon</b>
      </h3>
      <Form>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Code
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Leave blank for random code"
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Usage Limit
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="number"
              placeholder="Number of times the code can be used ex: 3"
              onChange={(e) => {
                setUsageLimit(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Valid till
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="date"
              onChange={(e) => {
                setDayLimit(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Valid category
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setValidCategory(e.target.value);
              }}
            >
              <option value="All">All categories</option>
              {categories.map((cat, i) => (
                <option key={i} value={cat}>
                  {cat}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Valid product
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Enter all or specific product id"
              value={validProduct}
              onChange={(e) => {
                setValidProduct(e.target.value);
              }}
            ></Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Discount type
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setDiscountType(e.target.value);
              }}
            >
              <option value="Percentage">Percentage</option>
              <option value="Fixed">Fixed</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Discount amount
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="number"
              onChange={(e) => {
                setDiscount(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Button
          sm={{ span: 2, offset: 8 }}
          variant="primary"
          onClick={() => {
            createCoupon();
          }}
        >
          Submit
        </Button>
      </Form>
      <h3 className="mt-4">
        <b>Coupons</b>
      </h3>
      <Table className="mt-4" hover>
        <thead>
          <tr>
            <th>Code</th>
            <th>Valid categories</th>
            <th>Valid product</th>
            <th>Valid till</th>
            <th>Usage limit</th>
            <th>Used</th>
            <th>Discount type</th>
            <th>Discount amount</th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((attr, i) => (
            <tr key={i}>
              <td>{attr.code}</td>
              <td>{attr.validCategory}</td>
              <td>{attr.validProduct}</td>
              <td>{moment(attr.dayLimit).format("DD/MM/YYYY")}</td>
              <td>{attr.usageLimit}</td>
              <td>{attr.used}</td>
              <td>{attr.discountType}</td>
              <td>{attr.discountAmount}</td>
              <td style={{ textAlign: "center" }}>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  as={Button}
                  onClick={() => {
                    deleteCoupon(attr.code);
                  }}
                  icon={faTrash}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Coupon;
