import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./App.scss";

import Home from "./components/Home";
import EditProduct from "./components/EditProduct";
import AddProduct from "./components/AddProduct";
import Attributes from "./components/Attributes";
import Category from "./components/Category";
import AllProducts from "./components/AllProducts";
import Orders from "./components/Orders";
import Login from "./components/Login";
import AuthRoute from "./components/AuthRoute";
import Coupon from "./components/Coupon";
import Customer from "./components/Customer";
import Blog from "./components/Blog";
import EmailSMS from "./components/EmailSMS";
import Slider from "./components/Slider";
import Scheduler from "./components/Scheduler";
import Subscriber from "./components/Subscriber";
import Wishlist from "./components/Wishlist";
import Events from "./components/Events";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />
        <AuthRoute path="/" exact={true} component={Home} />
        <AuthRoute path="/edit-product/:id" component={EditProduct} />
        <AuthRoute path="/events" component={Events} />
        <AuthRoute path="/add-product" component={AddProduct} />
        <AuthRoute path="/product-attributes" component={Attributes} />
        <AuthRoute path="/product-categories" component={Category} />
        <AuthRoute path="/products" component={AllProducts} />
        <AuthRoute path="/orders" component={Orders} />
        <AuthRoute path="/coupons" component={Coupon} />
        <AuthRoute path="/customers" component={Customer} />
        <AuthRoute path="/wishlist" component={Wishlist} />
        <AuthRoute path="/subscribers" component={Subscriber} />
        <AuthRoute path="/blogs" component={Blog} />
        <AuthRoute path="/email-sms" component={EmailSMS} />
        <AuthRoute path="/slider" component={Slider} />
        <AuthRoute path="/scheduler" component={Scheduler} />
        <AuthRoute path="*" component={Home} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
