import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Accordion,
  Card,
} from "react-bootstrap";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import axios from "axios";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const AddProduct = () => {
  const [productName, setProductName] = useState("");
  const [productShortDescription, setProductShortDescription] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState();
  const [productSku, setProductSku] = useState("");
  const [stockStatus, setStockStatus] = useState("inStock");
  const [slug, setSlug] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("SAREE");
  const [tags, setTags] = useState([]);
  const [priority, setPriority] = useState(5);
  const [multiSelect, setMultiSelect] = useState({
    options: [],
    checkedAttributes: [],
    subOptions: [],
    checkedSubOptions: [],
  });
  const slugs = [
    "None",
    "Solids",
    "Thalaivar",
    "Fandom",
    "TV series",
    "Namma ooru",
    "Funky",
    "Couples",
    "Combo",
  ];

  useEffect(() => {
    //console.log(multiSelect)
  }, [multiSelect]);

  useEffect(() => {
    console.log(tags);
  }, [tags]);

  const fetchAttributes = async () => {
    try {
      const res = await axios("api/attributes", {
        method: "get",
      });
      await setMultiSelect({
        options: multiSelect.options.concat(res.data.data),
        checkedAttributes: multiSelect.checkedAttributes,
        subOptions: multiSelect.subOptions,
        checkedSubOptions: multiSelect.checkedSubOptions,
      });
      //await console.log(multiSelect.options);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await axios("api/categories/show", {
        method: "get",
      });
      await setCategoryList(res.data.data);
      //await console.log(categoryList);
      await console.log(category); //, categoryList[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAttributes();
    fetchCategories();
  }, []);

  const getSubOptions = (checkedAttributes) => {
    console.log(checkedAttributes);
    if (checkedAttributes.length !== 0) {
      checkedAttributes.forEach(async (attribute) => {
        //console.log("getting sub for ", attribute);
        try {
          let { subOptions } = multiSelect;
          const check = subOptions.filter((op) => op.name === attribute);
          if (check.length === 0) {
            const res = await axios(`/api/attribute/${attribute}`, {
              method: "get",
            });
            subOptions = subOptions.concat({
              name: attribute,
              values: res.data.data,
            });
            await setMultiSelect({
              options: multiSelect.options,
              checkedAttributes: checkedAttributes,
              subOptions: subOptions,
              checkedSubOptions: multiSelect.checkedSubOptions,
            });
            await console.log(multiSelect);
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  };

  const addToChecked = async (mode, option) => {
    if (mode === "main") {
      let { checkedAttributes } = multiSelect;
      checkedAttributes = checkedAttributes.concat(option);
      //console.log(checkedAttributes);
      await setMultiSelect({
        options: multiSelect.options,
        checkedAttributes: checkedAttributes,
        subOptions: multiSelect.subOptions,
        checkedSubOptions: multiSelect.checkedSubOptions,
      });
      await getSubOptions(checkedAttributes);
    }
    if (mode === "sub") {
      let { checkedSubOptions } = multiSelect;
      checkedSubOptions = checkedSubOptions.concat(option);
      //console.log(checkedSubOptions);
      await setMultiSelect({
        options: multiSelect.options,
        checkedAttributes: multiSelect.checkedAttributes,
        subOptions: multiSelect.subOptions,
        checkedSubOptions: checkedSubOptions,
      });
    }
  };

  const removeFromChecked = async (mode, option) => {
    if (mode === "main") {
      let { checkedAttributes, subOptions } = multiSelect;
      checkedAttributes = checkedAttributes.filter((ca) => ca !== option);
      console.log(checkedAttributes);
      subOptions = subOptions.filter((so) => so.name !== option);
      await setMultiSelect({
        options: multiSelect.options,
        checkedAttributes: checkedAttributes,
        subOptions: subOptions,
        checkedSubOptions: multiSelect.checkedSubOptions,
      });
    }
    if (mode === "sub") {
      let { checkedSubOptions } = multiSelect;
      checkedSubOptions = checkedSubOptions.filter((ca) => ca !== option);
      //console.log(checkedSubOptions);
      setMultiSelect({
        options: multiSelect.options,
        checkedAttributes: multiSelect.checkedAttributes,
        subOptions: multiSelect.subOptions,
        checkedSubOptions: checkedSubOptions,
      });
    }
  };

  const addToSubChecked = async (option, value) => {
    let { checkedSubOptions } = multiSelect;
    const check = checkedSubOptions.filter((o) => o.name === option);
    if (check.length === 0) {
      checkedSubOptions = checkedSubOptions.concat({
        name: option,
        value: [value],
      });
      await setMultiSelect({
        options: multiSelect.options,
        checkedAttributes: multiSelect.checkedAttributes,
        subOptions: multiSelect.subOptions,
        checkedSubOptions: checkedSubOptions,
      });
      //console.log(multiSelect.checkedSubOptions);
    } else {
      const updated = [];
      checkedSubOptions.forEach((element) => {
        if (element.name === option) {
          element.value.push(value);
          updated.push({
            name: element.name,
            value: element.value,
          });
        } else {
          updated.push({
            name: element.name,
            value: element.value,
          });
        }
      });
      await setMultiSelect({
        options: multiSelect.options,
        checkedAttributes: multiSelect.checkedAttributes,
        subOptions: multiSelect.subOptions,
        checkedSubOptions: updated,
      });
      //console.log(multiSelect.checkedSubOptions);
    }
  };

  const removeFromSubChecked = async (option, va) => {
    let { checkedSubOptions } = multiSelect;
    const updated = [];
    checkedSubOptions.forEach((cso) => {
      if (cso.name === option) {
        const n = cso.value.filter((v) => v !== va);
        updated.push({
          name: cso.name,
          value: n,
        });
      } else {
        updated.push(cso);
      }
    });
    //console.log(updated);
    await setMultiSelect({
      options: multiSelect.options,
      checkedAttributes: multiSelect.checkedAttributes,
      subOptions: multiSelect.subOptions,
      checkedSubOptions: updated,
    });
  };

  const editorRef = useRef();

  const getConfig = (ht) => {
    return {
      height: ht,
      buttonList: buttonList.complex,
    };
  };

  const changeDescription = (content) => {
    setProductDescription(content);
  };

  const changeShortDescription = (content) => {
    setProductShortDescription(content);
  };

  const descriptionEditor = () => {
    return (
      <SunEditor
        ref={editorRef}
        setOptions={getConfig(200)}
        onChange={changeDescription}
      />
    );
  };

  const shortDescriptionEditor = () => {
    return (
      <SunEditor
        ref={editorRef}
        setOptions={getConfig(100)}
        onChange={changeShortDescription}
      />
    );
  };

  const submitForm = async (status) => {
    const token = JSON.parse(localStorage.getItem("admin")).token;
    if (!productName) {
      alert("Enter Product name");
    } else if (!productShortDescription) {
      alert("Enter Short Description");
    } else if (!productDescription) {
      alert("Enter Description");
    } else if (!productPrice) {
      alert("Enter Price");
    } else if (!productSku) {
      alert("Enter SKU");
    } else if (!stockStatus) {
      alert("Select Stock Status");
    } else {
      if (category === "") {
        await setCategory(categoryList[0]);
      }
      const file = document.getElementById("main-image").files[0];
      console.log(file);
      const formData1 = new FormData();
      formData1.append("mds", file);
      const res = await axios.post("api/products/images", formData1, {
        headers: { Authorization: "Bearer " + token },
      });
      const mainImage = await res.data.data;
      console.log(mainImage, res.data.data);
      const images = [];
      const files = document.getElementById("images").files;
      for (let i = 0; i < files.length; i++) {
        const formData1 = new FormData();
        formData1.append("mds", files[i]);
        const res = await axios.post("api/products/images", formData1, {
          headers: { Authorization: "Bearer " + token },
        });
        const { data } = res.data;
        await images.push(data);
      }
      const variation = [];
      const varCards = document.getElementsByClassName("varCard");
      console.log(varCards);
      for (let i = 0; i < varCards.length; i++) {
        const mainVar = varCards[i].children[0].children[0].value;
        const subVar = varCards[i].children[0].children[1].value;
        const sku =
          varCards[i].children[0].children[2].children[1].children[0].value;
        const price =
          varCards[i].children[0].children[3].children[1].children[0].value;
        const img =
          varCards[i].children[0].children[4].children[1].children[0]
            .children[0].files[0];
        let imag;
        if (img !== undefined) {
          const formData1 = new FormData();
          formData1.append("mds", img);
          const res = await axios.post("api/products/images", formData1, {
            headers: { Authorization: "Bearer " + token },
          });
          const { data } = res.data;
          imag = data;
        } else {
          imag = "";
        }
        console.log(
          `main: ${mainVar}, sub: ${subVar}, sku: ${sku}, price: ${price}, image: ${imag}`
        );

        const check = variation.filter((v) => v.name === mainVar);
        if (check.length === 0) {
          variation.push({
            name: mainVar,
            attr: [subVar],
            sku: [sku],
            price: [parseInt(price, 10)],
            image: [imag],
          });
        } else {
          check[0].attr.push(subVar);
          check[0].sku.push(sku);
          check[0].price.push(parseInt(price, 10));
          check[0].image.push(imag);
        }
        console.log("updated", variation);
      }
      console.log(variation);
      let prices = [];
      variation.forEach((vari) => {
        console.log(Math.max.apply(Math, vari.price));
        if (isNaN(Math.max.apply(Math, vari.price))) {
          prices.push(0);
        } else {
          prices.push(Math.max.apply(Math, vari.price));
        }
      });
      console.log(prices);
      let mPrice;
      if (prices.length !== 0) {
        mPrice = Math.max.apply(Math, prices);
        if (mPrice === 0) {
          mPrice = productPrice;
        }
      } else {
        mPrice = productPrice;
      }
      console.log(category);
      axios("/api/products", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: {
          name: productName,
          stockStatus: stockStatus,
          status: status,
          category: category,
          description: productDescription,
          shortDescription: productShortDescription,
          slug: slug,
          tags,
          priority,
          price: productPrice,
          mPrice: mPrice,
          sku: productSku,
          image: mainImage,
          images: images,
          variations: variation,
        },
      })
        .then((res) => {
          alert(res.data.data);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          alert("Please try again later");
        });
    }
  };

  const handleTags = (tags) => {
    setTags(tags);
  };

  return (
    <Container style={{ margin: "2em auto" }}>
      <Form className="add-form">
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Name
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Product Name"
              value={productName}
              onChange={(e) => {
                setProductName(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Short Description
          </Form.Label>
          <Col sm={10}>{shortDescriptionEditor()}</Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Product Description
          </Form.Label>
          <Col sm={10}>{descriptionEditor()}</Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Price
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="price"
              value={productPrice}
              onChange={(e) => {
                setProductPrice(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            SKU
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="SKU"
              value={productSku}
              onChange={(e) => {
                setProductSku(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Slug
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              placeholder="Slug"
              value={slug}
              onChange={(e) => {
                setSlug(e.target.value);
              }}
            >
              {slugs.map((cat, i) => (
                <option key={i} value={cat}>
                  {cat}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Tags
          </Form.Label>
          <Col sm={10}>
            <TagsInput value={tags} onChange={handleTags} />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Priority
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setPriority(e.target.value);
              }}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Category
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              {categoryList.map((cat, i) => (
                <option key={i} value={cat}>
                  {cat}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Stock Status
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setStockStatus(e.target.value);
              }}
            >
              <option value="inStock">In Stock</option>
              <option value="outOfStock">Out Of Stock</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Attributes
          </Form.Label>
          <Col sm={10}>
            <Accordion>
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="Button"
                  eventKey="0"
                  style={{ cursor: "pointer" }}
                >
                  Attributes
                </Accordion.Toggle>
                <Accordion.Collapse as={Card.Body} eventKey="0">
                  <div>
                    {multiSelect.options.map((option, i) => (
                      <Form.Check
                        type="checkbox"
                        key={i}
                        label={option}
                        onChange={(e) => {
                          e.target.checked
                            ? addToChecked("main", option)
                            : removeFromChecked("main", option);
                        }}
                      />
                    ))}
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Variations
          </Form.Label>
          <Col sm={10}>
            {multiSelect.checkedAttributes.map((attribute, i) => (
              <Accordion key={i}>
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    variant="Button"
                    eventKey="0"
                    style={{ cursor: "pointer" }}
                  >
                    {attribute}
                  </Accordion.Toggle>
                  <Accordion.Collapse as={Card.Body} eventKey="0">
                    <div>
                      {multiSelect.subOptions
                        .filter((o) => o.name === attribute)
                        .map((option, j) =>
                          option.values.map((val, k) => (
                            <Form.Check
                              type="checkbox"
                              key={k}
                              label={val}
                              onChange={(e) => {
                                e.target.checked
                                  ? addToSubChecked(option.name, val)
                                  : removeFromSubChecked(option.name, val);
                              }}
                            />
                          ))
                        )}
                    </div>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            ))}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Variation Data
          </Form.Label>
          <Col sm={10}>
            {multiSelect.checkedSubOptions.map((option, i) =>
              option.value.map((element, j) => (
                <Card key={j} className="varCard">
                  <Card.Body>
                    <input
                      className="varMainAttr"
                      value={option.name}
                      name="varMainAttr"
                      disabled
                    />
                    <input
                      className="varSubCat"
                      value={element}
                      name="varSubCat"
                      disabled
                    />
                    <Form.Group as={Row}>
                      <Form.Label column sm={4}>
                        SKU
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          placeholder="SKU"
                          name="varProductSku[]"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={4}>
                        Price
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          placeholder="Price"
                          name="varProductPrice[]"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={4}>
                        Image
                      </Form.Label>
                      <Col sm={8}>
                        <Form.File id="varProdImg[]" />
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              ))
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Image
          </Form.Label>
          <Col sm={10}>
            <input type="file" id="main-image" />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Gallery
          </Form.Label>
          <Col sm={10}>
            <Form.File id="images" multiple />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm={{ span: 2, offset: 8 }}>
            <Button
              className="ml-auto"
              onClick={() => {
                submitForm("draft");
              }}
            >
              Save as draft
            </Button>
          </Col>
          <Col sm={{ span: 1 }}>
            <Button
              className="ml-auto"
              onClick={() => {
                submitForm("publish");
              }}
            >
              Publish
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default AddProduct;
