import React, { useState, useRef, useEffect } from "react";
import { Container, Form, Row, Col, Button, Card } from "react-bootstrap";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const EditProduct = ({ match }) => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  console.log(id);
  const [productName, setProductName] = useState("");
  const [productShortDescription, setProductShortDescription] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productSku, setProductSku] = useState("");
  const [stockStatus, setStockStatus] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [tags, setTags] = useState([]);
  const [priority, setPriority] = useState(5);
  const [variations, setVariations] = useState([
    {
      name: "",
      attr: [],
      price: [],
      image: [],
      sku: [],
    },
  ]);
  const [productSlug, setProductSlug] = useState("");
  const [images, setImages] = useState([]);

  useEffect(() => {
    const mds = async () => {
      await axios("/api/product/" + id)
        .then(async (res) => {
          const prod = await res.data;
          console.log("res", res);
          await setProductName(prod.name);
          await setProductShortDescription(prod.shortDescription);
          await setProductDescription(prod.description);
          await setProductPrice(prod.price);
          await setProductSku(prod.sku);
          await setProductSlug(prod.slug);
          await setCategory(prod.category.split(",")[0]);
          await setStockStatus(prod.stockStatus);
          await setVariations(prod.variations);
          await setImages(prod.images);
          await setImage(prod.image);
          await setTags(prod.tags);
          await setTags(prod.tags);
          await setPriority(prod.priority);
          await setMS();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    mds();
  }, []);

  const setMS = async () => {
    await axios("/api/product/" + id)
      .then(async (res) => {
        console.log(res.data);
        setVariations(res.data.variations);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCategories = async () => {
    try {
      const res = await axios("/api/categories/show", {
        method: "get",
      });
      await setCategoryList(res.data.data);
      //await console.log(categoryList);
      await console.log(category); //, categoryList[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const editorRef = useRef();

  const getConfig = (ht) => {
    return {
      height: ht,
      buttonList: buttonList.complex,
    };
  };

  const changeDescription = (content) => {
    setProductDescription(content);
  };

  const changeShortDescription = (content) => {
    setProductShortDescription(content);
  };

  const descriptionEditor = () => {
    return (
      <SunEditor
        ref={editorRef}
        setOptions={getConfig(200)}
        setContents={productDescription}
        onChange={changeDescription}
      />
    );
  };

  const handleTags = (tags) => {
    setTags(tags);
  };

  const slugs = [
    "None",
    "Solids",
    "Thalaivar",
    "Fandom",
    "TV series",
    "Namma ooru",
    "Funky",
    "Couples",
    "Combo",
  ];

  const shortDescriptionEditor = () => {
    return (
      <SunEditor
        ref={editorRef}
        setOptions={getConfig(100)}
        setContents={productShortDescription}
        onChange={changeShortDescription}
      />
    );
  };

  const submitForm = async (status) => {
    const token = JSON.parse(localStorage.getItem("admin")).token;
    if (!productName) {
      alert("Enter Product name");
    } else if (!productShortDescription) {
      alert("Enter Short Description");
    } else if (!productDescription) {
      alert("Enter Description");
    } else if (!productPrice) {
      alert("Enter Price");
    } else if (!productSku) {
      alert("Enter SKU");
    } else if (!stockStatus) {
      alert("Select Stock Status");
    } else {
      let prices = [];
      variations.forEach((vari) => {
        console.log(Math.max.apply(Math, vari.price));
        if (isNaN(Math.max.apply(Math, vari.price))) {
          prices.push(0);
        } else {
          prices.push(Math.max.apply(Math, vari.price));
        }
      });
      console.log(prices);
      let mPrice;
      if (prices.length !== 0) {
        mPrice = Math.max.apply(Math, prices);
      } else {
        mPrice = productPrice;
      }
      console.log(variations, mPrice);
      await axios(`/api/products/updateMultiple`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "post",
        data: {
          id,
          productName,
          productShortDescription,
          productDescription,
          productSku,
          productSlug,
          productPrice,
          category,
          variations,
          image,
          images,
          tags,
          priority,
          stockStatus,
          status,
          mPrice,
        },
      })
        .then((res) => {
          console.log("updated res", res);
          alert(res.data.data);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const changeImages = async () => {
    const token = JSON.parse(localStorage.getItem("admin")).token;
    const images = document.getElementById("images").files;
    let imgs = [];
    for (let i = 0; i < images.length; i++) {
      const file = await images[i];
      const formData = new FormData();
      formData.append("mds", file);
      const res = await axios.post(`/api/products/images`, formData, {
        headers: { Authorization: "Bearer " + token },
      });
      await imgs.push(res.data.data);
    }
    setImages(imgs);
  };

  const deleteImages = async (i) => {
    console.log(i, images[i], images);
    delete images[i];
    let imgs = await images.filter((im) => im !== null);
    await setImages(imgs);
  };

  const changeImage = async (file) => {
    const token = JSON.parse(localStorage.getItem("admin")).token;
    const formData = new FormData();
    formData.append("mds", file);
    const res = await axios.post(`/api/products/images`, formData, {
      headers: { Authorization: "Bearer " + token },
    });
    await setImage(res.data.data);
  };

  const changeSku = async (sku, i, j) => {
    variations[i].sku[j] = sku;
    setVariations(variations);
  };
  const changePrice = async (sku, i, j) => {
    variations[i].price[j] = sku;
    setVariations(variations);
  };
  const changeVimage = async (file, i, j) => {
    const token = JSON.parse(localStorage.getItem("admin")).token;
    const formData = new FormData();
    formData.append("mds", file);
    const res = await axios(`/api/products/images`, formData, {
      headers: { Authorization: "Bearer " + token },
    });
    variations[i].image[j] = res.data.data;
    setVariations(variations);
  };

  return (
    <Container style={{ margin: "2em auto" }}>
      <Form className="add-form">
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Name
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Product Name"
              value={productName}
              onChange={(e) => {
                setProductName(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Short Description
          </Form.Label>
          <Col sm={10}>{shortDescriptionEditor()}</Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Product Description
          </Form.Label>
          <Col sm={10}>{descriptionEditor()}</Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Price
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="price"
              value={productPrice}
              onChange={(e) => {
                setProductPrice(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            SKU
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="SKU"
              value={productSku}
              onChange={(e) => {
                setProductSku(e.target.value);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Slug
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setProductSlug(e.target.value);
              }}
              defaultChecked={productSlug}
            >
              {slugs.map((cat, i) => (
                <option key={i} value={cat}>
                  {cat}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Tags
          </Form.Label>
          <Col sm={10}>
            <TagsInput value={tags} onChange={handleTags} />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Priority
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              value={priority}
              onChange={(e) => {
                setPriority(e.target.value);
              }}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Category
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              defaultChecked={category}
            >
              {categoryList.map((cat, i) => (
                <option key={i} value={cat}>
                  {cat}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Stock Status
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setStockStatus(e.target.value);
              }}
              defaultChecked={stockStatus}
            >
              <option value="inStock">In Stock</option>
              <option value="outOfStock">Out Of Stock</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Variations
          </Form.Label>
          <Col sm={10}>
            {variations?.map((option, i) =>
              option?.attr?.map((element, j) => (
                <Card key={j} className="varCard">
                  <Card.Body>
                    <input
                      className="varMainAttr"
                      value={option?.name}
                      name="varMainAttr"
                      disabled
                    />
                    <input
                      className="varSubCat"
                      value={option?.attr[j]}
                      name="varSubCat"
                      disabled
                    />
                    <Form.Group as={Row}>
                      <Form.Label column sm={4}>
                        SKU
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          placeholder="SKU"
                          onBlur={(e) => {
                            changeSku(e.target.value, i, j);
                          }}
                        />
                        {option?.sku[j]}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={4}>
                        Price
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          placeholder="Price"
                          onBlur={(e) => {
                            changePrice(e.target.value, i, j);
                          }}
                        />
                        {option?.price[j]}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={4}>
                        Image
                      </Form.Label>
                      <Col sm={8}>
                        <img
                          style={{ width: "5em" }}
                          src={"/api/image/" + option?.image[j]}
                          alt="no pic"
                        />
                        <Form.File
                          onChange={(e) => {
                            changeVimage(e.target.files[0], i, j);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              ))
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Image
          </Form.Label>
          <Col sm={10}>
            <img
              style={{ width: "12em", zIndex: 1 }}
              src={"/api/image/" + image}
              alt="no pic"
            />
            <br />
            <input
              type="file"
              id="main-image"
              onChange={(e) => {
                changeImage(e.target.files[0]);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Gallery
          </Form.Label>
          <Col sm={10} style={{ display: "flex", flexWrap: "wrap" }}>
            {images?.map((image, i) => (
              <div
                key={i}
                style={{
                  width: "10em",
                  position: "relative",
                  margin: "0 0.3em",
                }}
              >
                <input
                  type="file"
                  onChange={(e) => {
                    changeImage(i, e.target.files[0]);
                  }}
                  style={{
                    position: "absolute",
                    opacity: 0,
                    height: "100%",
                    width: "100%",
                    zIndex: 1,
                  }}
                />
                <img
                  style={{ width: "100%", zIndex: 1 }}
                  src={"/api/image/" + image}
                  alt=""
                />
                <FontAwesomeIcon
                  as={Button}
                  style={{
                    margin: "auto 0.5em",
                    fontSize: "1.5em",
                    cursor: "pointer",
                    fontWeight: "bold",
                    zIndex: 2,
                    position: "absolute",
                    top: 8,
                    right: 0,
                    color: "black",
                    padding: 0,
                  }}
                  onClick={() => {
                    deleteImages(i);
                  }}
                  icon={faTrash}
                />
              </div>
            ))}
            <Form.File
              id="images"
              multiple
              onChange={(e) => {
                changeImages();
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm={{ span: 2, offset: 8 }}>
            <Button
              className="ml-auto"
              onClick={() => {
                submitForm("draft");
              }}
            >
              Save as draft
            </Button>
          </Col>
          <Col sm={{ span: 1 }}>
            <Button
              className="ml-auto"
              onClick={() => {
                submitForm("publish");
              }}
            >
              Publish
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default EditProduct;
