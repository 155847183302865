import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const login = () => {
    if (!username || !password) {
      alert("Please enter the details");
    } else {
      axios(`/api/user/signin`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        data: {
          username: username,
          password: password,
        },
      }).then((res) => {
        if (res.data.token !== undefined) {
          localStorage.setItem("admin", JSON.stringify(res.data));
          history.push("/");
        } else {
          alert(res.data.data);
        }
      });
    }
  };

  return (
    <Container style={{ marginTop: "10%" }}>
      <Form
        style={{
          width: "22em",
          margin: "auto",
          background: "lightblue",
          padding: "2em",
          borderRadius: "1em",
        }}
      >
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Username</Form.Label>
          <Form.Control
            value={username}
            type="email"
            placeholder="Enter email"
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Group>
        <Button
          variant="primary"
          onClick={() => {
            login();
          }}
        >
          Login
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
